import { MutableRefObject, Ref, useCallback, useMemo, useRef } from 'react';

import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';
import {
  defaultTweenSpace,
  TweenContext,
  TweenNormalizedPosition,
  TweenOption,
  TweenSpace,
  useTween,
} from '@/hooks/useTween';
import { debug } from '@/utils/debug';
import { easeLinear } from '@/utils/easing';

import { SwipeState, SwipeType, useSwipe } from './useSwipe';

const DEBUG_SWIPE_WITH_TWEEN = false;
const log = DEBUG_SWIPE_WITH_TWEEN ? debug('useSwipeWithTween') : () => {};

export type SwipeTweenContext<C = never> = TweenContext<SwipeTweenTag<C>>;
export type SwipeTweenTag<C = never> =
  | { name: 'swipe'; type: SwipeType }
  | { name: 'snap'; type: SwipeType; initial: TweenNormalizedPosition; offset: number }
  | { name: 'updateIndex' }
  | { name: 'freeze' }
  | C;

export type SwipeTweenAnimatedTag<C = never> = Exclude<SwipeTweenTag<C>, { name: 'swipe' | 'freeze' }>;

type Callbacks<C> = {
  updateItem: (offset: TweenNormalizedPosition, context: SwipeTweenContext<C>) => void;
  updateIsSwiping: (isSwiping: boolean) => void;
};

type SwipeOption = {
  itemWidth: MutableRefObject<number>;
  maxSwipeItem?: MutableRefObject<number>;
};

export type SwipeTweenSpace<C = never> = TweenSpace<SwipeTweenTag<C>> & {
  snap?: (value: number, tag: SwipeTweenTag<C>) => TweenNormalizedPosition;
};

type SwipeTweenOption<C> = {
  animation: (
    currentVelocity: number,
    tag: SwipeTweenTag<C>,
  ) => Pick<TweenOption<SwipeTweenTag<C>>, 'easing' | 'duration'> & { tag?: C };
  space?: SwipeTweenSpace<C>;
};

type UseSwipeWithTween<C> = {
  swipeRef: Ref<HTMLDivElement>;
  indexRef: MutableRefObject<TweenNormalizedPosition>;
  updateIndex: (nextIndex?: number, tag?: C) => void;
};

const SWIPE_VELOCITY_WINDOW = 4;
const SWIPE_VELOCITY_WINDOW_TICK = 50;
const SWIPE_VELOCITY_COMPENSATION = 4000;

const createWindow = (defaultValue = 0) => Array.from<number>({ length: SWIPE_VELOCITY_WINDOW }).fill(defaultValue);
const defaultSwipeWindow = createWindow();

const defaultSnap =
  <C>(space: TweenSpace<C>) =>
  (value: number, tag: C) =>
    space.normalize(Math.round(value), tag);
const defaultSwipeTweenSpace = {
  ...defaultTweenSpace,
  snap: defaultSnap(defaultTweenSpace),
};

/**
 * @name useSwipeWithTween
 * @description Index가 변경될 때 Tween 해주는 것과 Swipe를 합친 훅
 *   CSS Transition 을 써도 정상 작동하나, 커스텀 동작 구현 또는 특정 디바이스에서 한 박자 늦은 애니메이션 이슈를
 *   수정하기 위해 Tween과 같이 스와이프를 쓸 때를 위한 커스텀 훅
 * @param {SwipeOption} swipeOption - 스와이프를 어떻게 쓸지에 대한 옵션
 * @param {SwipeTweenOption} tweenOption - useTween에 제공되는 옵션
 * @returns useSwipe의 결과
 *   swipe.updateIndex - Swipe를 초기화하고 가까운 index (+ offset)로 이동
 */
export const useSwipeWithTween = <C = never>(
  callbacks: Callbacks<C>,
  swipeOption: SwipeOption,
  tweenOption: SwipeTweenOption<C>,
): UseSwipeWithTween<C> => {
  /*
   * References
   */
  const itemIndex = useRef(0 as TweenNormalizedPosition);
  const initialIndex = useRef(0 as TweenNormalizedPosition);

  const windowPointer = useRef(0);
  const windowOffset = useRef(defaultSwipeWindow);
  const windowUpdateAt = useRef(defaultSwipeWindow);
  const velocityWindowed = useRef(0);

  const resetSwipeRef = useRef(() => {});
  const finalizeSwipeRef = useRef((_offset: number, _state: SwipeState) => {});

  /*
   * Tween Behaviour & Item Updaters
   */
  const { updateItem, updateIsSwiping } = callbacks;
  const updateItemIndex = useCallback(
    (newIndex: TweenNormalizedPosition, context: SwipeTweenContext<C>) => {
      itemIndex.current = newIndex;
      updateItem(itemIndex.current, context);
    },
    [updateItem],
  );

  const swipeTweenSpace = useMemo(() => {
    const tweenSpace = tweenOption.space ?? defaultSwipeTweenSpace;
    return { ...tweenSpace, snap: tweenSpace.snap ?? defaultSnap(tweenSpace) };
  }, [tweenOption.space]);

  const updateItemIndexTweened = useTween<SwipeTweenTag<C>>(updateItemIndex, {
    easing: easeLinear,
    duration: 0,
    space: swipeTweenSpace,
  });

  /*
   * Swipe Behaviour
   */
  const updateSwipeState = useCallback(
    (state: SwipeState) => {
      const { offset, isFirst, isFinal, type } = state;
      const now = Date.now();
      if (isFirst) {
        // Freeze current index
        updateItemIndexTweened(itemIndex.current, { name: 'freeze' }, { immediate: true });
        initialIndex.current = itemIndex.current;
        windowPointer.current = 0;
        windowOffset.current = createWindow(offset);
        windowUpdateAt.current = createWindow(now).map(
          (value, index, { length }) => value - (length - index) * SWIPE_VELOCITY_WINDOW_TICK,
        );

        velocityWindowed.current = 0;
        return;
      }

      // Update windowed values
      if (windowUpdateAt.current[windowPointer.current] + SWIPE_VELOCITY_WINDOW_TICK * SWIPE_VELOCITY_WINDOW < now) {
        windowOffset.current[windowPointer.current] = offset;
        windowUpdateAt.current[windowPointer.current] = now;
        windowPointer.current = (windowPointer.current + 1) % SWIPE_VELOCITY_WINDOW;
      }

      const pointer = windowPointer.current;
      velocityWindowed.current = (offset - windowOffset.current[pointer]) / (now - windowUpdateAt.current[pointer]);

      // Apply to the tween
      const indexOffset = offset / swipeOption.itemWidth.current;
      if (!isFinal) {
        // Linear interpolate between pointer events
        // As interval between events of some devices are lower than 60Hz
        updateItemIndexTweened(
          initialIndex.current + indexOffset,
          { name: 'swipe', type },
          { duration: 32, easing: easeLinear },
        );
      } else {
        finalizeSwipeRef.current(indexOffset, state);
      }

      log('Swipe', {
        windowOffset: windowOffset.current.join(','),
        velocityWindowed: velocityWindowed.current,
        offset: indexOffset,
      });
    },
    [updateItemIndexTweened, swipeOption.itemWidth],
  );

  const { swipeRef, setSwipeStateRef } = useSwipe({ updateState: updateSwipeState, updateIsSwiping });
  resetSwipeRef.current = () => setSwipeStateRef.current(0);

  /*
   * Swipe Exit Tweening
   */
  const animationRef = useRef(tweenOption.animation);
  animationRef.current = tweenOption.animation;

  const finalizeSwipe = (offset: number, state: SwipeState) => {
    const maxSwipe = state.type === 'touch' ? swipeOption.maxSwipeItem?.current ?? Infinity : Infinity;
    const itemWidth = swipeOption.itemWidth.current;

    const velocityCompensation = state.isCanceled ? 0 : SWIPE_VELOCITY_COMPENSATION;
    const destinationOffset = offset + (velocityWindowed.current / itemWidth) * velocityCompensation;
    const destinationOffsetClamped = Math.max(-maxSwipe, Math.min(destinationOffset, maxSwipe));

    const tag = {
      name: 'snap',
      type: state.type,
      initial: initialIndex.current,
      offset: destinationOffsetClamped,
    } as const;
    const nextIndex = swipeTweenSpace.snap(initialIndex.current + destinationOffsetClamped, tag);
    const animation = animationRef.current(velocityWindowed.current, tag);
    updateItemIndexTweened(nextIndex, animation.tag ?? tag, animation);
    resetSwipeRef.current();

    log('Snap', {
      offset,
      compensation: destinationOffset,
      nextIndex,
      easing: animation.easing.name,
      duration: animation.duration,
      tag: animation.tag ?? tag,
    });
  };
  finalizeSwipeRef.current = finalizeSwipe;

  /*
   * Index-related API
   */
  const getIntegerIndex = useCallback(
    () =>
      swipeTweenSpace.snap(itemIndex.current, { name: 'snap', type: 'general', initial: itemIndex.current, offset: 0 }),
    [swipeTweenSpace],
  );

  const updateIndex = useCallback(
    (nextIndex?: number, customTag?: C) => {
      const tag = customTag ?? ({ name: 'updateIndex' } as const);
      const animation = animationRef.current(0, tag);
      updateItemIndexTweened(nextIndex ?? getIntegerIndex(), animation.tag ?? tag, animation);
      resetSwipeRef.current();
    },
    [updateItemIndexTweened, getIntegerIndex],
  );

  const indexRef = useMemo<MutableRefObject<TweenNormalizedPosition>>(
    () => ({
      get current() {
        return getIntegerIndex();
      },
      set current(newValue) {
        updateIndex(newValue);
      },
    }),
    [getIntegerIndex, updateIndex],
  );

  useIsomorphicLayoutEffect(() => {
    updateIndex();
  }, [updateIndex]);

  return { swipeRef, indexRef, updateIndex };
};
