import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { getParsedCookie, setCookie } from '@/utils/cookie';

export const useAdultInclude = (): [boolean, (isAdultincluded: boolean) => void] => {
  const router = useRouter();
  const [isAdultIncludedState, setIsAdultIncludedState] = useState(false);

  const setIsAdultIncluded = useCallback((value: boolean) => {
    setCookie('adult_include', value ? 'y' : 'n', { path: '/', sameSite: 'lax', maxAge: 60 * 60 * 24 * 365 });
    setIsAdultIncludedState(value);
  }, []);

  // Run on client to get cookies
  useEffect(() => {
    if (router.query.adult_include) {
      setIsAdultIncluded(router.query.adult_include === 'y');
      return;
    }

    const cookie = getParsedCookie(document.cookie);
    setIsAdultIncludedState(cookie?.adult_include === 'y');
  }, [router.query.adult_include, setIsAdultIncluded]);

  return [isAdultIncludedState, setIsAdultIncluded];
};
