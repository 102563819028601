import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export type TweenContext<T> = {
  start: {
    position: MutableRefObject<TweenNormalizedPosition>;
    time: MutableRefObject<TweenTime>;
  };
  difference: {
    position: MutableRefObject<TweenDelta>;
    time: MutableRefObject<TweenTime>;
  };
  space: TweenSpace<T>;
  easing: MutableRefObject<TweenEasing>;
  tag: T;
};

export type TweenAction<T> = (value: TweenNormalizedPosition, context: TweenContext<T>) => void;

export type TweenTime = number;
export type TweenPosition = number;
export type TweenNormalizedPosition = Brand<number, 'NormalizedPosition'>;
export type TweenDelta = Brand<number, 'Delta'>;
export type TweenEasing = (t: TweenTime) => TweenTime;

/**
 * @name TweenSpace
 * @desc 예를 들어 1시에서 12시까지 있는 시계 위에서 보간을 한다고 가정해봅시다.
 *
 * 1시에서 11시로 가는 가장 가까운 방법은 +10시간을 하는 게 아니라 -2시간일 것입니다.
 * 마찬가지로, 11시에서 1시로 가는 가장 가까운 방법은 +2시간이 될 것입니다.
 * 이 값을 계산해주는 함수가 diff입니다.
 *
 * 또한, 1시에서 -2시간을 뺀 -1시는 11시가 될 것입니다.
 * 이 -1시를 11시로 정규화하는 함수가 normalize입니다.
 */
export type TweenSpace<T = unknown> = {
  diff: (from: TweenNormalizedPosition, to: TweenPosition, tag: T) => TweenDelta;
  normalize: (position: TweenPosition, tag: T) => TweenNormalizedPosition;
};

export const defaultTweenSpace: TweenSpace = {
  diff: (from, to) => (to - from) as TweenDelta,
  normalize: position => position as TweenNormalizedPosition,
};

export const circularTweenSpace = (circulation: TweenPosition): TweenSpace => {
  const normalize = (value: number) => (((value % circulation) + circulation) % circulation) as TweenNormalizedPosition;
  const halfCirculation = circulation / 2;

  return {
    normalize,
    diff(from, to) {
      const normalizedTo = normalize(to);

      let delta = normalizedTo - from;
      if (delta > halfCirculation) {
        delta -= circulation;
      } else if (delta < -halfCirculation) {
        delta += circulation;
      }

      return delta as TweenDelta;
    },
  };
};

export interface TweenOption<T> {
  easing: TweenEasing;
  duration: number;
  space?: TweenSpace<T>;
}

interface TweenOverride {
  easing?: TweenEasing;
  duration?: number;
  immediate?: boolean;
}
type UseTween<T> = (value: number, tag: T, override?: TweenOverride) => void;

/**
 * @name useTween
 * @description 원하는 값까지 AnimationFrame 마다 보간해주는 함수를 반환하는 훅
 * @param {(value: number) => void} action - 매 animationFrame마다 보간된 값과 함께 실행될 함수
 * @param {TweenOption} option - useTween의 옵션
 * @param {Easing} option.easing - Easing 함수
 * @param {number} option.duration - 애니메이션 길이
 * @param {TweenSpace} option.space - 비선형적인 공간에서 보간할 시, 해당 공간을 명시하는 값
 * @returns {(value: number, override?: TweenOverride) => void} - 원하는 값으로 업데이트하는 함수
 *   duration, easing을 오버라이딩 가능
 *   immediate가 true일 경우에는 원하는 값으로 즉시 변경됨
 */
export const useTween = <T = undefined>(action: TweenAction<T>, option: TweenOption<T>): UseTween<T> => {
  const position = useRef(0 as TweenNormalizedPosition);
  const delta = useRef(0 as TweenDelta);
  const startPosition = useRef(0 as TweenNormalizedPosition);
  const startTime = useRef(0);
  const lastAnimationFrame = useRef(performance.now());

  const duration = useRef(option.duration);
  const easingFunction = useRef(option.easing);
  const tweenSpace = option.space ?? defaultTweenSpace;

  const contextRef = useRef<TweenContext<T>>({
    start: {
      position: startPosition,
      time: startTime,
    },
    difference: {
      position: delta,
      time: duration,
    },
    space: tweenSpace,
    easing: easingFunction,
    tag: null as never,
  });
  contextRef.current.space = tweenSpace;

  const animationFrameIdRef = useRef<number | null>(null);

  const updateAnimation = useCallback(() => {
    const now = performance.now();
    const deltaTime = now - startTime.current;
    const progress = duration.current <= 0 ? 1 : Math.min(1, deltaTime / duration.current);
    const easedProgress = easingFunction.current(progress);

    position.current = contextRef.current.space.normalize(
      startPosition.current + easedProgress * delta.current,
      contextRef.current.tag,
    );
    lastAnimationFrame.current = now;

    return progress < 1;
  }, []);

  const actionRef = useRef(action);
  actionRef.current = action;

  const updateAnimationFrame = useCallback(() => {
    const needsUpdate = updateAnimation();
    actionRef.current(position.current, contextRef.current);

    if (needsUpdate) {
      animationFrameIdRef.current = requestAnimationFrame(updateAnimationFrame);
    } else {
      animationFrameIdRef.current = null;
    }
  }, [updateAnimation]);

  const updateTween = useCallback<UseTween<T>>(
    (value, tag, override = {}) => {
      delta.current = tweenSpace.diff(position.current, value, tag);
      startPosition.current = position.current;

      const minLastAnimationFrame = performance.now() - 1000 / 60;
      startTime.current = Math.max(minLastAnimationFrame, lastAnimationFrame.current);

      duration.current = override.duration ?? option.duration;
      easingFunction.current = override.easing ?? option.easing;
      contextRef.current.tag = tag;

      if (override.immediate) {
        duration.current = 0;
      }

      if (animationFrameIdRef.current === null) {
        animationFrameIdRef.current = requestAnimationFrame(updateAnimationFrame);
      }
    },
    [tweenSpace, option.duration, option.easing, updateAnimationFrame],
  );

  useEffect(
    () => () => {
      if (animationFrameIdRef.current !== null) {
        cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = null;
      }
    },
    [],
  );

  return updateTween;
};
