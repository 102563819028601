// 1차
export const easeLinear = (x: number): number => x;

const easeInPolynomial =
  (order: number) =>
  (x: number): number =>
    x ** order;

const easeOutPolynomial =
  (order: number) =>
  (x: number): number =>
    1 - (1 - x) ** order;

const easeInOutPolynomial =
  (order: number) =>
  (x: number): number =>
    x < 0.5 ? 2 ** (order - 1) * x ** order : 1 - (-2 * x + 2) ** order / 2;

// 2차
export const easeInOutQuad = easeInOutPolynomial(2);
export const easeInQuad = easeInPolynomial(2);
export const easeOutQuad = easeOutPolynomial(2);

// 3차
export const easeInOutCubic = easeInOutPolynomial(3);
export const easeInCubic = easeInPolynomial(3);
export const easeOutCubic = easeOutPolynomial(3);

// 5차
export const easeInOutQuint = easeInOutPolynomial(5);
export const easeInQuint = easeInPolynomial(5);
export const easeOutQuint = easeOutPolynomial(5);
