import { MutableRefObject, Ref, useCallback, useEffect, useMemo, useRef } from 'react';

import { debug } from '@/utils/debug';

// ==== Debug ====
const DEBUG_SWIPE = false;
const debugSwipe = DEBUG_SWIPE ? debug('useSwipe') : () => {};

// ==== Types ====
export type SwipeCallbacks = {
  updateState: (state: SwipeState) => void;
  updateIsSwiping: (isSwiping: boolean) => void;
};

export type SwipeType = 'touch' | 'wheel' | 'general';
export type SwipeState = {
  offset: number;
  isFirst: boolean;
  isFinal: boolean;
  isCanceled: boolean;
  type: SwipeType;
};

export type UseSwipe = {
  swipeRef: Ref<HTMLDivElement>;
  setSwipeStateRef: MutableRefObject<(offset: number) => void>;
};

type UseSwipeFn = (callbacks: SwipeCallbacks) => UseSwipe;

export type SwipeContext = {
  updateState: MutableRefObject<(state: SwipeState) => void>;
  updateIsSwiping: MutableRefObject<(isSwiping: boolean) => void>;
};

// ==== Wheel Handler ====
const DOM_DELTA_PIXEL = 0x00;
const WHEEL_THRESHOLD = 500;
const WHEEL_CONSECUTIVE_TIMEOUT = 150;

const getWheelEventMultiplier = (event: WheelEvent) =>
  typeof event.deltaMode === 'number' && event.deltaMode !== DOM_DELTA_PIXEL ? WHEEL_THRESHOLD : 1;

export const useWheelHandler = (ctx: SwipeContext) => {
  const wheelTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const wheelDelta = useRef(0);
  const lastScrollSign = useRef(1);

  const setWheelState = useCallback(
    (offset: number, isCanceled = false) => {
      if (wheelTimeoutId.current !== null) {
        clearTimeout(wheelTimeoutId.current);
      }

      ctx.updateState.current({ offset, isFirst: false, isFinal: true, isCanceled, type: 'wheel' });
      ctx.updateIsSwiping.current(false);
      wheelTimeoutId.current = null;
      wheelDelta.current = 0;
    },
    [ctx],
  );

  const onWheel = useCallback(
    (event: WheelEvent) => {
      if (Math.abs(event.deltaX) < Math.abs(event.deltaY)) {
        return;
      }

      event.preventDefault();

      const scrollAmount = getWheelEventMultiplier(event) * event.deltaX;
      const scrollSign = Math.sign(scrollAmount);
      if (scrollSign !== 0 && scrollSign !== lastScrollSign.current) {
        // Cancel wheel when reverse scroll
        if (wheelTimeoutId.current !== null) {
          setWheelState(wheelDelta.current);
          clearTimeout(wheelTimeoutId.current);
          wheelTimeoutId.current = null;
        }

        lastScrollSign.current = scrollSign;
      }

      // Accumulate consecutive wheel scroll amount
      // (Check if the scroll has stopped using timeout)
      let isFirst = true;

      if (wheelTimeoutId.current !== null) {
        clearTimeout(wheelTimeoutId.current);
        wheelTimeoutId.current = null;
        isFirst = false;
      }

      const offset = wheelDelta.current;

      wheelTimeoutId.current = setTimeout(() => {
        setWheelState(offset);
      }, WHEEL_CONSECUTIVE_TIMEOUT);

      wheelDelta.current += scrollAmount;
      ctx.updateIsSwiping.current(true);
      ctx.updateState.current({
        offset,
        isFirst,
        isFinal: false,
        isCanceled: false,
        type: 'wheel',
      });
    },
    [ctx, setWheelState],
  );

  return { onWheel, setWheelState };
};

// ==== Pointer Handler ====
const IOS_EDGE_THRESHOLD = 30;
const POINTER_ENTRY_THRESHOLD = 20;
const DEGREE_30 = (30 / 180) * Math.PI;
const isIOSPointerEvent = (event: PointerEvent): boolean => 'pageX' in event;

export const usePointerHandler = (ctx: SwipeContext) => {
  const isHandling = useRef<boolean | null>(null);
  const isNavigationGesture = useRef(false);
  const shouldBlockScroll = useRef(false);
  const pointerStart = useRef({ x: 0, y: 0 });
  const pointerLast = useRef({ x: 0, y: 0 });

  const setPointerState = useCallback(
    (offset: number, isCanceled = false) => {
      isHandling.current = null;
      shouldBlockScroll.current = false;
      ctx.updateState.current({ offset, isFirst: false, isFinal: true, isCanceled, type: 'touch' });
      ctx.updateIsSwiping.current(false);
    },
    [ctx],
  );

  const onPointerDown = useCallback((event: PointerEvent) => {
    if (event.pointerType === 'mouse') {
      return;
    }

    pointerStart.current.x = event.clientX;
    pointerStart.current.y = event.clientY;
    pointerLast.current.x = event.clientX;
    pointerLast.current.y = event.clientY;

    isHandling.current = false;
    isNavigationGesture.current =
      isIOSPointerEvent(event) &&
      (pointerStart.current.x > window.innerWidth - IOS_EDGE_THRESHOLD || pointerStart.current.x < IOS_EDGE_THRESHOLD);

    debugSwipe('Down', { isNavigationGesture: isNavigationGesture.current });
  }, []);

  const onPointerMove = useCallback(
    (event: PointerEvent) => {
      if (event.pointerType === 'mouse') {
        return;
      }

      if (isNavigationGesture.current) {
        debugSwipe('Move/ignore', { reason: 'iOSNavigationGesture' });
        return;
      }

      let isFirst = false;
      const travelX = Math.abs(event.clientX - pointerStart.current.x);
      const travelY = Math.abs(event.clientY - pointerStart.current.y);
      if (isHandling.current === false) {
        if (travelX >= POINTER_ENTRY_THRESHOLD && travelX > travelY) {
          isFirst = true;
          isHandling.current = true;
          ctx.updateIsSwiping.current(true);
          debugSwipe('Move/startHandle');

          pointerStart.current.x = event.clientX;
          pointerStart.current.y = event.clientY;
        }
      }

      if (!isHandling.current) {
        return;
      }

      pointerLast.current.x = event.clientX;
      pointerLast.current.y = event.clientY;

      const deltaX = pointerStart.current.x - event.clientX;
      ctx.updateState.current({ offset: deltaX, isFirst, isCanceled: false, isFinal: false, type: 'touch' });
      shouldBlockScroll.current = shouldBlockScroll.current || Math.atan2(travelY, travelX) < DEGREE_30;

      debugSwipe('Move', { offset: deltaX, block: shouldBlockScroll.current });
    },
    [ctx],
  );

  const onPointerUp = useCallback(
    (event: PointerEvent) => {
      if (event.pointerType === 'mouse') {
        return;
      }

      if (!isHandling.current) {
        return;
      }

      const deltaX = pointerStart.current.x - event.clientX;
      debugSwipe('Up', { hold: pointerStart.current.x, release: event.clientX, offset: deltaX });
      setPointerState(deltaX);
    },
    [setPointerState],
  );

  const onPointerCancel = useCallback(
    (event: PointerEvent) => {
      if (event.pointerType === 'mouse') {
        return;
      }

      if (!isHandling.current) {
        return;
      }

      const deltaX = pointerStart.current.x - pointerLast.current.x;
      debugSwipe('Cancel', { hold: pointerStart.current.x, release: pointerLast.current.x, offset: deltaX });
      setPointerState(deltaX, true);
    },
    [setPointerState],
  );

  const onTouchMove = useCallback((event: TouchEvent) => {
    if (shouldBlockScroll.current) {
      event.preventDefault();
    }
  }, []);

  const pointerHandlers = useMemo(
    () => ({ onPointerDown, onPointerMove, onPointerUp, onPointerCancel, onTouchMove, setPointerState }),
    [onPointerDown, onPointerMove, onPointerUp, onPointerCancel, onTouchMove, setPointerState],
  );

  return pointerHandlers;
};

/**
 * @name useSwipe
 * @description 터치 및 트랙패드를 통한 좌/우 스와이프를 핸들링
 * 유저가 스와이프시 `updateSwipeOffset` 이 스와이프 정도와 함께 호출됨
 *
 * @param {SwipeCallbacks} callbacks - 스와이프 시작 / 종료, 스와이프 시 호출될 콜백 함수들
 * @returns
 *   swipe.swipeRef - 스와이프 대상에 달아줘야 할 ref
 *   swipe.setSwipeStateRef - Swipe 내부 상태를 초기화하는 함수를 가진 ref
 */
export const useSwipe: UseSwipeFn = (callbacks: SwipeCallbacks) => {
  const swipeRef = useRef<HTMLDivElement>(null);

  const updateStateRef = useRef(callbacks.updateState);
  const updateIsSwipingRef = useRef(callbacks.updateIsSwiping);
  updateStateRef.current = callbacks.updateState;
  updateIsSwipingRef.current = callbacks.updateIsSwiping;

  const context: SwipeContext = useMemo(
    () => ({
      updateState: updateStateRef,
      updateIsSwiping: updateIsSwipingRef,
    }),
    [],
  );

  const wheelHandler = useWheelHandler(context);
  const pointerHandler = usePointerHandler(context);

  useEffect(() => {
    if (swipeRef.current) {
      const previousTouchAction = swipeRef.current.style.touchAction;
      const swipeElem = swipeRef.current;

      swipeElem.style.touchAction = 'pan-y';
      swipeElem.addEventListener('wheel', wheelHandler.onWheel, { passive: false });
      swipeElem.addEventListener('touchmove', pointerHandler.onTouchMove, { passive: false });
      swipeElem.addEventListener('pointerup', pointerHandler.onPointerUp);
      swipeElem.addEventListener('pointermove', pointerHandler.onPointerMove);
      swipeElem.addEventListener('pointerdown', pointerHandler.onPointerDown);
      swipeElem.addEventListener('pointercancel', pointerHandler.onPointerCancel);

      return () => {
        swipeElem.style.touchAction = previousTouchAction;
        swipeElem.removeEventListener('wheel', wheelHandler.onWheel);
        swipeElem.removeEventListener('touchmove', pointerHandler.onTouchMove);
        swipeElem.removeEventListener('pointerup', pointerHandler.onPointerUp);
        swipeElem.removeEventListener('pointermove', pointerHandler.onPointerMove);
        swipeElem.removeEventListener('pointerdown', pointerHandler.onPointerDown);
        swipeElem.removeEventListener('pointercancel', pointerHandler.onPointerCancel);
      };
    }

    return () => {};
  }, [wheelHandler, pointerHandler]);

  const setSwipeState = useCallback(
    (offset: number) => {
      // Temporarily disable updates
      const previousUpdateState = updateStateRef.current;
      const previousUpdateIsSwiping = updateIsSwipingRef.current;

      updateIsSwipingRef.current = () => {};
      updateStateRef.current = () => {};

      wheelHandler.setWheelState(offset, true);
      pointerHandler.setPointerState(offset, true);

      updateIsSwipingRef.current = previousUpdateIsSwiping;
      updateStateRef.current = previousUpdateState;
    },
    [wheelHandler, pointerHandler],
  );

  const setSwipeStateRef = useRef(setSwipeState);
  setSwipeStateRef.current = setSwipeState;

  return { swipeRef, setSwipeStateRef };
};
